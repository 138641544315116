import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import LoadingButton from "./join_button";

const MailForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [status, setStatus] = useState({ message: "", type: "" });
  const [success, setSuccess] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [progress, setProgress] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    setProgress("loading...");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setEmailError(
        "Email is not valid. Email addresses should follow the format user@domain.com."
      );
      return { ok: false }; // Return a mock response to indicate failure
    }

    setEmailError("");

    try {
      const response = await fetch(
        "https://formkeep.com/f/618bd6d2e0db",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        setStatus({
          message: "Subscription successful!",
          type: "success",
        });
        setFormData({ email: "" });
        setSuccess(true);
        setProgress("successfully submitted");

        // Delay hiding the form to show success message
        setTimeout(() => {
          setSubmitted(true);
          setStatus({ message: "", type: "" }); // Clear success message
        }, 2000); // 2 seconds delay

        return response;
      } else {
        setStatus({
          message: data.error || "Something went wrong",
          type: "error",
        });
        setSuccess(false);
        setProgress("failed");
        return response;
      }
    } catch (error) {
      setStatus({
        message: "Failed to submit form. Please try again.",
        type: "error",
      });
      setSuccess(false);
      setProgress("failed");
      return { ok: false }; // Return a mock response to indicate failure
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setEmailError("");
  };

  return (
    <Box
      sx={{
        mt: 4,
        bgcolor: "white",
        p: 4,
        borderRadius: 2,
        width: "100%",
        textAlign: "center",
        zoom: 1,
        
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item xs={12} sx={{ mb: 0,}}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "medium", color: "#315FD9" }}
          >
            Join the waitlist!
          </Typography>
          <Typography variant="h6" sx={{ mt: 2, mb: 0, }}>
            Be the first to know what we're up to!
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {!submitted ? (
            <Box sx={{ mt: 4 }}>
              <LoadingButton
                formData={formData}
                handleChange={handleChange}
                emailError={emailError}
                handleSubmit={handleSubmit}
              />
              {status.type === "error" && status.message && (
                <Typography
                  variant="body1"
                  sx={{
                    color: "red",
                    mt: 2,
                  }}
                >
                  {status.message}
                </Typography>
              )}
            </Box>
          ) : (
            <Typography
              variant="h6"
              sx={{
                color: "#315fd9",
                fontWeight: "bold",
                mt: 2,
                textAlign: "center",
              }}
            >
              Watch this space for news and updates!
            </Typography>
          )}
        </Grid>
      </Grid>
      <br></br>
    </Box>
  );
};

export default MailForm;
